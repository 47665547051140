import React, { useState, useEffect } from 'react';
import Header from './components/header';
import DesktopProfile from './components/profile/desktopProfile';
import MobileProfile from './components/profile/mobileProfile';
import AddSocialModal from './components/addSocialModal';
import EditUserModal from './components/editUserModal';
import ModalConfirmAction from './components/modalConfirmAction';

const UserProfile = () => {
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const [User, setUser] = useState(user);
  const [addSocialModal, setAddSocialModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [modalConfirmAction, setModalConfirmAction] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isEditing, setIsEditing] = useState(false); // State to control whether to show the edit screen

  useEffect(() => {
    checkActions();
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 768);
    });
  }, []);

  const checkActions = async () => {
    const unloggedContact = localStorage.getItem('@SKANZ/unloggedcontact');
    if (unloggedContact) {
      try {
        setModalConfirmAction(true);
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  // Function to open the edit modal
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setIsEditing(false);
  };

  return (
    <>
      {!isMobile && <Header user={User}/>}
      {isMobile ? (
        <MobileProfile
          modalOpen={addSocialModal || modalConfirmAction}
          setAddSocialModal={setAddSocialModal}
          handleEditClick={handleEditClick} // Pass the function to open the edit modal
        />
      ) : (
        <DesktopProfile
          modalOpen={addSocialModal || editUserModal || modalConfirmAction}
          setAddSocialModal={setAddSocialModal}
          setEditUserModal={setEditUserModal}
        />
      )}
      {addSocialModal && (
        <AddSocialModal addSocialModal={addSocialModal} setAddSocialModal={setAddSocialModal} />
      )}
      {isEditing && ( // Render the EditUserModal when isEditing is true
        <EditUserModal
          setEditUserModal={setEditUserModal}
          handleCloseEditModal={handleCloseEditModal} // Pass the function to close the edit modal
        />
      )}
      {modalConfirmAction && (
        <ModalConfirmAction
          setModalConfirmAction={setModalConfirmAction}
          type="contact"
        />
      )}
    </>
  );
};

export default UserProfile;
