// Importing React and necessary hooks
import React, { useState, useEffect } from 'react';

// Importing components
import Header from './components/header';
import DesktopContent from './components/dashboard/desktopDashboard'; // Import the desktop-specific dashboard
import MobileContent from './components/dashboard/mobileDashboard'; // Import the mobile-specific dashboard
import ModalJoinEvent from './components/modalJoinEvent';
import ModalAddTattoo from './components/modalAddTattoo';
import ModalEditTattoo from './components/modalEditTattoo';
import ModalConfirmAction from './components/modalConfirmAction';
import ModalQrCode from './components/qrCodeModal';

// UserDashboard component
const UserDashboard = () => {
  // State variables
  const user = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const [User, setUser] = useState(user);
  const [modalJoinEvent, setModalJoinEvent] = useState(false);
  const [modalAddTattoo, setModalAddTattoo] = useState(false);
  const [modalEditTattoo, setModalEditTattoo] = useState(false);
  const [modalConfirmAction, setModalConfirmAction] = useState(false);
  const [modalQR, setModalQR] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detect if the screen is mobile

  // useEffect hook
  useEffect(() => {
    checkActions();
    // Listen for window resize events to update isMobile state
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 768);
    });
  }, []);

  // Function to check actions
  const checkActions = async () => {
    const unloggedQr = localStorage.getItem('@SKANZ/unloggedqr');
    if (unloggedQr) {
      setModalConfirmAction(true);
    }
  };

  // Component JSX
  return (
    <>
      {/* Render Header only if it's not mobile */}
      {!isMobile && <Header user={User}/>}
      {/* Render the appropriate dashboard based on screen size */}
      {isMobile ? (
        <MobileContent
          // Pass the props as needed
        />
      ) : (
        <DesktopContent
          // Pass the props as needed
        />
      )}
      {modalJoinEvent && (
        <ModalJoinEvent setModalJoinEvent={setModalJoinEvent} />
      )}
      {modalAddTattoo && (
        <ModalAddTattoo setAddTattooModal={setModalAddTattoo} />
      )}
      {modalEditTattoo && (
        <ModalEditTattoo
          editTattooModal={modalEditTattoo}
          setEditTattooModal={setModalEditTattoo}
        />
      )}
      {modalConfirmAction && (
        <ModalConfirmAction
          setModalConfirmAction={setModalConfirmAction}
          type="qr"
        />
      )}
      {modalQR && <ModalQrCode modalQR={modalQR} setModalQR={setModalQR} />}
    </>
  );
};

// Exporting the UserDashboard component
export default UserDashboard;
