import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Header from "../../components/header/header";
import DesktopHome from "./components/desktopHome";
import MobileHome from "./components/mobileHome";

import Footer from "../../components/footer";

const Home = () => {
  const history = useHistory();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*useEffect(() => {
    // Redirect the user to https://skanz.io
    window.location.href = "https://skanz.io";
  }, []);*/

  return (
    <>
      {!isMobile && <Header />}
      {isMobile ? <MobileHome /> : <DesktopHome />}
      {!isMobile && <Footer />}
    </>
  );
};

export default Home;


