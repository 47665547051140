import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { UseUser } from './utils/hooks/User/user';
import { useParams } from 'react-router';
import LoggedFooter from './components/loggedFooter';
import Home from './pages/home';
import Login from './pages/login';
import SignUp from './pages/signup';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import HowItWorks from './pages/how-it-works';
import JoinEvent from './pages/join-event';
import BusinessDashboard from './pages/business-dashboard';
import Contact from './pages/contact';
import JoinOrganization from './pages/join-organization';

import AdminDashboard from './pages/admin/dashboard';
import AdminTattoos from './pages/admin/tattoos';
import AdminUsers from './pages/admin/users';
import AdminRequests from './pages/admin/requests';
import AdminBatch from './pages/admin/batch';
import AdminQr from './pages/admin/qr';
import AdminBusiness from './pages/admin/business';
import BusinessDetails from './pages/admin/business/businessDetails';

import AdminEvents from './pages/admin/events';
import AdminEventsDetails from './pages/admin/events/details';

import UserDashboard from './pages/user/dashboard';
import UserProfile from './pages/user/profile';
import UserShare from './pages/user/share';
import UserContacts from './pages/user/contacts';
import UserCart from './pages/user/cart';
import UserSettings from './pages/user/settings';
import UserEvents from './pages/user/events';
import UserQuests from './pages/user/quests';
import UserBusiness from './pages/user/business';
import ExternalProfile from './pages/external';
import RedirectQr from './pages/redirect';
import LoginAlert from './pages/loginAlert';
import Terms from './pages/terms';

import QuestRedirect from './pages/user/questRedirect';


const isLogged = JSON.parse(localStorage.getItem('@SKANZ/user'));

const LoggedCheck = () => {
  const { qrCodeToJoin } = useParams();
  if (!isLogged && qrCodeToJoin) {
    localStorage.setItem('@SKANZ/joinEventLink', JSON.stringify(qrCodeToJoin));
  }
  if (isLogged) {
    const { data: user, isSuccess } = UseUser(isLogged.userslug);
    if (isSuccess) {
      localStorage.setItem('@SKANZ/user', JSON.stringify(user.data.user));
    }
    const linktoJoin = JSON.parse(localStorage.getItem('@SKANZ/joinEventLink'));
    if (linktoJoin) {
      localStorage.removeItem('@SKANZ/joinEventLink');
      return (window.location.href = `/join-event/${linktoJoin}`);
    }
  }
  if (!isLogged) {
    const linktoJoin = localStorage.getItem('@SKANZ/joinEventLink');
    if (linktoJoin) {
      return (window.location.href = `/join-event`);
    }
    return (window.location.href = '/');
  }
  return <></>;
};

const CodeToJoinCheck = () => {
  const { codeToJoin } = useParams();
  localStorage.setItem('@SKANZ/qrCodeToJoin', JSON.stringify(codeToJoin));
  return <></>;
};

const AdminCheck = () => {
  if (!isLogged) return (window.location.href = '/');
  if (isLogged?.roleId !== 'admin') return (window.location.href = '/');
  return <></>;
};


export default function Routes() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 767);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 767);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/badges/1/:variable">
        <QuestRedirect templatePath="user/components/questRedirect.js" />
      </Route>

      <Route path="/join-event/:qrCodeToJoin">
        <LoggedCheck />
        <UserContacts />
      </Route>
      <Route path="/join-event">
        <JoinEvent />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/how-it-works">
        <HowItWorks />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/sign-up">
        <SignUp />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/business-dashboard/event/:id">
        <LoggedCheck />
        <BusinessDashboard />
        <LoggedFooter />
      </Route>
      <Route path="/business-dashboard/:route">
        <LoggedCheck />
        <BusinessDashboard />
        <LoggedFooter />
      </Route>
      <Route path="/business-dashboard">
        <LoggedCheck />
        <BusinessDashboard />
        <LoggedFooter />
      </Route>
      <Route path="/admin/dashboard">
        <LoggedCheck />
        <AdminCheck />
        <AdminDashboard />
      </Route>
      <Route path="/admin/requests">
        <AdminCheck />
        <AdminRequests />
      </Route>
      <Route path="/admin/codes/:selector">
        <AdminCheck />
        <AdminTattoos />
      </Route>
      <Route path="/admin/users/:selector">
        <AdminCheck />
        <AdminUsers />
      </Route>
      <Route path="/admin/events">
        <AdminCheck />
        <AdminEvents />
      </Route>
      <Route path="/admin/business/:businessId">
        <AdminCheck />
        <BusinessDetails />
      </Route>
      <Route path="/admin/business">
        <AdminCheck />
        <AdminBusiness />
      </Route>
      <Route path="/admin/event/:eventId">
        <AdminCheck />
        <AdminEventsDetails />
      </Route>
      <Route path="/admin/batch/:batchId">
        <AdminCheck />
        <AdminBatch />
      </Route>
      <Route path="/admin/qr/:qrId">
        <AdminCheck />
        <AdminQr />
      </Route>
      <Route path="/dashboard">
        <LoggedCheck />
        <UserDashboard />
        {isDesktop && <LoggedFooter />}
      </Route>
      <Route path="/profile/:codeToJoin">
        <LoggedCheck />
        <UserProfile />
        <LoggedFooter />
      </Route>
      <Route path="/profile">
        <LoggedCheck />
        <UserProfile />
        {isDesktop && <LoggedFooter />}
      </Route>
      <Route path="/share">
        <LoggedCheck />
        <UserShare />
        {isDesktop && <LoggedFooter />}
      </Route>
      <Route path="/contacts">
        <LoggedCheck />
        <UserContacts />
        {isDesktop && <LoggedFooter />}
      </Route>
      <Route path="/settings">
        <LoggedCheck />
        <UserSettings />
        {isDesktop && <LoggedFooter />}
      </Route>
      <Route path="/events">
        <LoggedCheck />
        <UserEvents />
        {isDesktop && <LoggedFooter />}
      </Route>
      <Route path="/quests">
        <LoggedCheck />
        <UserQuests />
        <LoggedFooter />
      </Route>
      <Route path="/business">
        <LoggedCheck />
        <UserBusiness />
      </Route>
      <Route path="/cart">
        <LoggedCheck />
        <UserCart />
        <LoggedFooter />
      </Route>
      <Route path="/out-profile/:slug">
        <ExternalProfile />
      </Route>
      <Route path="/qr/:qrcode">
        <RedirectQr />
      </Route>
      <Route path="/redirect/:qrcode">
        <RedirectQr />
      </Route>
      <Route path="/alert">
        <LoginAlert />
      </Route>
      <Route exact path="/:codeToJoin">
        <CodeToJoinCheck />
        <JoinOrganization />
      </Route>
    </Switch>
  );
}