import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useStore } from '../../../store/user';
import { saveContact, findSocial } from '../../../utils/User';
import CircularProgress from '@mui/material/CircularProgress';
import { Carousel } from 'react-responsive-carousel';
import { useSnackbar } from 'notistack';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import api from '../../../utils/api';

import './external.css';

const saveOnSkanz = (user, event) => {
  const localUser = localStorage.getItem('@SKANZ/user');
  localStorage.setItem(
    '@SKANZ/unloggedcontact',
    JSON.stringify({ user: user, event: event?._id }),
  );
  if (!localUser) {
    window.open('/alert', '_blank').focus();
  } else {
    window.open('/profile', '_blank').focus();
  }
};

const ContentDesktop = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const eventId = new URLSearchParams(location.search).get('e');
  const [user, setUser] = useState();
  const [event, setEvent] = useState(null);
  const [color, setColor] = useState(null);
  const [business, setBusiness] = useState(null);
  const [profileType, setProfileType] = useState(null);
  const [gallery, setGallery] = useState(null);

  const getUserProfile = async () => {
    try {
      const { data } = await api.get(`/user/slug/${slug}`);
      const user = data.user;
      let business = null;
      if (user.roleId !== 'employee') {
        setColor(user.profileIconColor.secondary);
      }
      if (data.user.organizationId) {
        const { data } = await api.get(
          `/business/${user.organizationId}/owners`,
        );
        business = data;
        setBusiness(data);
        if (user.roleId === 'employee' || user.plans.isOrganization) {
          setColor(data.profileColor);
        }
      }
      setProfileType(user.profileType);
      setGallery(user.gallery);
      if (business) {
        setProfileType(business.profileType);
        setGallery(business.gallery);
        user.latitude = business.latitude;
        user.longitude = business.longitude;
      }
      if (
        (user.roleId === 'employee' || user.roleId === 'org_owner') &&
        user.employeeGallery
      ) {
        setGallery(user.gallery);
      }
      setUser(data.user);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getEvent = async () => {
    try {
      const { data } = await api.get(`/event/${eventId}`);
      setEvent(data.event);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserProfile();
    if (eventId) getEvent();
  }, []);

  if (!user) {
    return <></>;
  }

  return (
    <>
      <div className="skanz-logo">
        <a
          href="https://skanz.live"
          style={{
            width: user?.outProfileLogoStyle == 'Square' ? '' : '80%',
            textAlign: 'center',
          }}>
          {(user?.roleId === 'premium' || user?.plans.isPremium) && (
            <img
              style={{
                objectFit: 'contain',
                marginBottom: '20px',
                width: user?.outProfileLogoStyle == 'Square' ? '50%' : '100%',
                borderRadius: '5px',
                maxHeight: '200px',
              }}
              alt="logo"
              src={
                user?.outProfileLogo
                  ? user?.outProfileLogo
                  : '/assets/img/brand/logo.png'
              }
              id="navbar-logo"
            />
          )}

          {(user?.roleId === 'user' || user?.roleId === 'admin') &&
            user.plans.isPremium == false && (
              <img
                style={{
                  height: '80px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
                alt="logo"
                src="/assets/img/brand/logo.png"
                id="navbar-logo"
              />
            )}

          {user?.roleId === 'employee' && business?.logoUrl && (
            <img
              style={{
                objectFit: 'contain',
                marginBottom: '20px',
                marginTop: '10px',
                width:
                  business?.outProfileLogoStyle == 'Square' ? '50%' : '100%',
                maxHeight: '200px',
              }}
              alt="logo"
              src={business?.logoUrl}
              id="navbar-logo"
            />
          )}
          {user?.plans.isOrganization && business?.logoUrl && (
            <img
              style={{
                objectFit: 'contain',
                marginBottom: '20px',
                marginTop: '10px',
                width:
                  business?.outProfileLogoStyle == 'Square' ? '50%' : '100%',
                maxHeight: '200px',
              }}
              alt="logo"
              src={business?.logoUrl}
              id="navbar-logo"
            />
          )}
        </a>
      </div>

      {profileType === 'Standard' && (
        <StandardProfile
          user={user}
          event={event}
          color={color}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          enqueueSnackbar={enqueueSnackbar}
        />
      )}
      {profileType === 'Organization' && (
        <OrganizationProfile
          user={user}
          business={business}
          event={event}
          color={color}
          gallery={gallery}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          enqueueSnackbar={enqueueSnackbar}
        />
      )}
      {profileType === 'Premium' && (
        <PremiumProfile
          user={user}
          business={business}
          event={event}
          color={color}
          gallery={gallery}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          enqueueSnackbar={enqueueSnackbar}
        />
      )}
    </>
  );
};

const StandardProfile = ({
  user,
  event,
  color,
  isLoading,
  setIsLoading,
  enqueueSnackbar,
}) => {
  return (
    <>
      <div className="profile-container">
        <div className="profile-header" style={{ backgroundColor: color }}>
          {user.showProfilePicture ? (
            <>
              {user.profileImageFormat == 'Square' ? (
                <div className="profile-picture">
                  <img src={user.profilePicture} alt="profile" />
                </div>
              ) : (
                <>
                  <div className="profile-picture-circle">
                    <img src={user.profilePicture} alt="profile" />
                  </div>
                </>
              )}
            </>
          ) : (
            <div style={{ height: '100px' }}></div>
          )}
        </div>
        <div
          className={`${
            user.showProfilePicture ? 'profile-body' : 'profile-body no-padding'
          }`}>
          <div>
            <div className="profile-name">
              {user.firstName} {user.lastName}
            </div>
            <div className="profile-title">
              <p>
                {user.position ? user.position : ''}
                {user.company && user.position ? <strong>{' | '}</strong> : ' '}
                {user.company ? user.company : ''}
              </p>
            </div>
            <ContactPhonesList contactPhones={user.contactPhones} user={user} />
            <ContactEmailList contactEmails={user.contactEmails} />
          </div>

          <button
            className="profile-contact-button"
            style={{ backgroundColor: color }}
            onClick={async () => {
              setIsLoading(true);
              await saveContact(user);
              setIsLoading(false);
              enqueueSnackbar('Contact saved successfully', {
                variant: 'success',
              });
            }}>
            {isLoading ? <CircularProgress size={20} /> : 'Download Contact'}
          </button>

          {user.bio && <AboutUs text={user.bio} color={color} />}

          <div className="profile-socials">
            {user?.social
              .filter((item) => item.kind !== 'Url' && item.visible)
              .map((item) => (
                <MobileItem item={item} color={color} />
              ))}
          </div>

          <div className="profile-links">
            {user?.social
              .filter((item) => item.kind === 'Url' && item.visible)
              .map((item) => (
                <URLItem item={item} color={color} />
              ))}
          </div>

          <div className="profile-links">
            {user?.files
              .filter((item) => item.visible)
              .map((item) => (
                <FileItem item={item} color={color} />
              ))}
          </div>

          <div className="profile-skanz">
            <button
              style={{ backgroundColor: color }}
              className="profile-skanz-button"
              onClick={() => saveOnSkanz(user, event)}>
              <p>
                Save on <strong>SKANZ</strong>
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const OrganizationProfile = ({
  user,
  event,
  business,
  color,
  gallery,
  isLoading,
  setIsLoading,
  enqueueSnackbar,
}) => {
  return (
    <>
      <div
        className="profile-container-organization"
        style={{ backgroundColor: color }}>
        {gallery.length ? (
          <Carousel
            autoPlay
            showThumbs={false}
            showStatus={false}
            renderIndicator={false}
            swipeable
            emulateTouch
            className="p-3">
            <div className="image-container rounded p-2 d-flex justify-items-center h-100">
              <img
                src={user.profilePicture}
                className="profile-header-organization rounded"
                alt="profile"
              />
              <p></p>
            </div>
            {gallery.map((item, index) => (
              <div className="image-container rounded p-2 d-flex justify-items-center h-100">
                <img
                  src={item.url}
                  className="profile-header-organization rounded"
                  alt="profile"
                />
                <p></p>
              </div>
            ))}
          </Carousel>
        ) : (
          <img
            src={user.profilePicture}
            alt="profile"
            className="profile-header-organization"
          />
        )}
        <div className="profile-body-organization">
          <div>
            <div className="profile-name">
              {user.firstName} {user.lastName}
            </div>

            <div className="profile-socials profile-socials-organization ">
              {user?.social
                .filter((item) => item.kind !== 'Url' && item.visible)
                .map((item) => (
                  <MobileItem item={item} color={color} key={item.id} />
                ))}
            </div>

            <div className="profile-title">
              {user.position ? user.position : ''}{' '}
              {user.company ? user.company : ''}
            </div>
            <div className="profile-contacts">
              <ContactPhonesList
                contactPhones={user.contactPhones}
                user={user}
              />
              <ContactEmailList contactEmails={user.contactEmails} />
            </div>
          </div>

          <button
            className="profile-contact-button"
            style={{ backgroundColor: color }}
            onClick={async () => {
              setIsLoading(true);
              await saveContact(user);
              setIsLoading(false);
              enqueueSnackbar('Contact downloaded successfully', {
                variant: 'success',
              });
            }}>
            {isLoading ? <CircularProgress size={20} /> : 'Download Contact'}
          </button>

          {user.bio && <AboutUs text={user.bio} color={color} />}

          <div className="profile-links">
            {user?.social
              .filter((item) => item.kind === 'Url' && item.visible)
              .map((item) => (
                <URLItem item={item} color={color} key={item.id} />
              ))}
          </div>

          <div className="profile-links">
            {user?.files
              .filter((item) => item.visible)
              .map((item) => (
                <FileItem item={item} color={color} key={item.id} />
              ))}
          </div>

          {user.latitude && user.longitude && (
            <div className="w-80 mt-4 rounded">
              <iframe
                className="w-100 rounded"
                title="map"
                src={`https://maps.google.com/?q=${user.latitude},${user.longitude}&output=svembed`}
                loading="lazy"></iframe>
              <a
                href={`http://maps.google.com/?q=${business.address}`}
                className="text-dark text-underline">
                {business.address}
              </a>
            </div>
          )}

          <div className="profile-skanz">
            <button
              className="profile-skanz-button"
              style={{ backgroundColor: color }}
              onClick={() => saveOnSkanz(user, event)}>
              <p>
                Save on <strong>SKANZ</strong>
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const PremiumProfile = ({
  user,
  business,
  event,
  color,
  gallery,
  isLoading,
  setIsLoading,
  enqueueSnackbar,
}) => {
  return (
    <>
      <div className="profile-container container-premium">
        <div className="profile-header profile-header-premium ">
          <div className="profile-picture picture-premium">
            <img src={user.profilePicture} alt="profile" />
          </div>
          <div>
            <div className="profile-name premium-text profile-name-premium">
              {user.firstName} {user.lastName}
            </div>
            <div className="profile-title premium-text profile-title-premium">
              {user.position ? user.position : ''}{' '}
              {user.company ? user.company : ''}
            </div>
            <div className="profile-contacts">
              <ContactPhonesList
                contactPhones={user.contactPhones}
                premium={true}
                user={user}
              />
              <ContactEmailList
                contactEmails={user.contactEmails}
                premium={true}
              />
            </div>
            <button
              className="profile-contact-button"
              style={{ backgroundColor: color }}
              onClick={async () => {
                setIsLoading(true);
                await saveContact(user);
                setIsLoading(false);
                enqueueSnackbar('Contact saved successfully', {
                  variant: 'success',
                });
              }}>
              {isLoading ? <CircularProgress size={20} /> : 'Download Contact'}
            </button>
          </div>
        </div>
        <div className="profile-body-organization">
          <div className="profile-socials profile-socials-premium">
            {user?.social
              .filter((item) => item.kind !== 'Url' && item.visible)
              .map((item) => (
                <MobileItem item={item} color={color} />
              ))}
          </div>

          <Carousel
            autoPlay
            className="mt-5"
            showThumbs={false}
            showStatus={false}
            swipeable
            emulateTouch>
            {gallery.map((item) => (
              <div>
                <img
                  src={item.url}
                  style={{ maxWidth: '350px' }}
                  alt="profile"
                />
                <p></p>
              </div>
            ))}
          </Carousel>

          {user.bio && <AboutUs text={user.bio} color={color} />}

          <div className="profile-links">
            {user?.social
              .filter((item) => item.kind === 'Url' && item.visible)
              .map((item) => (
                <URLItem item={item} color={color} />
              ))}
          </div>

          <div className="profile-links">
            {user?.files
              .filter((item) => item.visible)
              .map((item) => (
                <FileItem item={item} color={color} />
              ))}
          </div>

          {user.latitude && user.longitude && (
            <div className="w-80 mt-4 rounded">
              <iframe
                className="w-100 rounded"
                title="map"
                src={`https://maps.google.com/?q=${user.latitude},${user.longitude}&output=svembed`}
                loading="lazy"></iframe>
              <a
                href={`http://maps.google.com/?q=${
                  user.address || business.address
                }`}
                className="text-dark text-underline">
                {user.address || business.address}
              </a>
            </div>
          )}

          <div className="profile-skanz">
            <button
              className="profile-skanz-button"
              style={{ backgroundColor: color }}
              onClick={() => saveOnSkanz(user, event)}>
              <p>
                Save on <strong>SKANZ</strong>
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ContactPhonesList = ({ contactPhones, premium, user }) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      {contactPhones.length > 0 && (
        <div className="profile-contact">
          {contactPhones.map((contactPhone, index) => {
            if (index === 0) {
              return (
                <>
                  <div
                    key={index}
                    className={`profile-contact-item ${
                      premium ? 'profile-contact-item-premium' : ''
                    }`}>
                    <i className="fas fa-phone-alt"></i>
                    <a href={`tel:${contactPhone}`}>{contactPhone}</a>
                  </div>
                  {contactPhones.length > 1 && (
                    <>
                      {!showAll && (
                        <button
                          className="show-more-btn"
                          onClick={handleShowAll}>
                          Show More
                        </button>
                      )}
                    </>
                  )}
                </>
              );
            }
            if (showAll) {
              return (
                <div
                  className={`profile-contact-item ${
                    premium ? 'profile-contact-item-premium' : ''
                  }`}>
                  <i className="fas fa-phone-alt"></i>
                  <a href={`tel:${contactPhone}`}>{contactPhone}</a>
                </div>
              );
            }
          })}
        </div>
      )}
      {showAll && (
        <button className="show-more-btn" onClick={handleShowAll}>
          Show Less
        </button>
      )}
    </>
  );
};

const ContactEmailList = ({ contactEmails, premium }) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      {contactEmails.length > 0 && (
        <div className="profile-contact">
          {contactEmails.map((contactEmail, index) => {
            if (index === 0) {
              return (
                <>
                  <div
                    key={index}
                    className={`profile-contact-item ${
                      premium ? 'profile-contact-item-premium' : ''
                    }`}>
                    <i className="fas fa-envelope"></i>
                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                  </div>
                  {contactEmails.length > 1 && (
                    <>
                      {!showAll && (
                        <button
                          className="show-more-btn"
                          onClick={handleShowAll}>
                          Show More
                        </button>
                      )}
                    </>
                  )}
                </>
              );
            }
            if (showAll) {
              return (
                <div
                  className={`profile-contact-item ${
                    premium ? 'profile-contact-item-premium' : ''
                  }`}>
                  <i className="fas fa-envelope"></i>
                  <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                </div>
              );
            }
          })}
        </div>
      )}
      {showAll && (
        <button className="show-more-btn" onClick={handleShowAll}>
          Show Less
        </button>
      )}
    </>
  );
};

const MobileItem = ({ item, color }) => {
  const social = findSocial(item);
  return (
    <a
      style={{ backgroundColor: color }}
      target="_blank"
      rel="noreferrer"
      href={item.data}>
      <img alt="social" src={`/assets/img/svg/social/${social.source}`} />
    </a>
  );
};

const URLItem = ({ item, color }) => {
  const url = item.data;
  const { hostname } = new URL(url);

  return (
    <div
      className="ml-first hover-float profile-link-item"
      onClick={() => window.open(item.data, '_blank')}>
      <div className="p-0 m-0 row">
        <div
          className="profile-link-item-icon"
          style={{ backgroundColor: color }}>
          <img alt="social" src="/assets/img/svg/social/link.png" />
        </div>
        <div className="profile-link-data">
          <div>{item.nickname || hostname}</div>
          <div>{item.data}</div>
        </div>
        <div
          style={{
            fontSize: 24,
            marginLeft: 'auto',
            height: '50px',
            width: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <FeatherIcon icon="external-link" />
        </div>
      </div>
    </div>
  );
};

const FileItem = ({ item, color }) => {
  return (
    <div
      className="ml-first hover-float profile-link-item"
      onClick={() => window.open(item.url, '_blank')}>
      <div className="p-0 m-0 row">
        <div
          className="profile-link-item-icon"
          style={{ backgroundColor: color }}>
          <img alt="social" src={`/assets/img/svg/icons/pdf-icon.svg`} />
        </div>
        <div className="profile-link-data">
          <div>
            <p>{item.nickname}</p>
          </div>
          <div>
            <p>{item.fileName}</p>
          </div>
        </div>
        <div
          style={{
            fontSize: 24,
            marginLeft: 'auto',
            height: '50px',
            width: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <FeatherIcon icon="external-link" />
        </div>
      </div>
    </div>
  );
};

const AboutUs = ({ text, color }) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  console.log(text);

  return (
    <>
      <div className="profile-about">
        {/* <div className="profile-about-title">About</div> */}
        <div className="profile-about-content">
          {text.length > 200 && !showMore ? (
            <>
              <div>{text.substring(0, 200)}...</div>
              <div>
                <button
                  style={{ backgroundColor: color }}
                  className="profile-skanz-button"
                  onClick={handleShowMore}>
                  Show More
                </button>
              </div>
            </>
          ) : (
            <>
              <div>{text}</div>
              {showMore && (
                <div>
                  <button
                    style={{ backgroundColor: color }}
                    className="profile-skanz-button"
                    onClick={handleShowMore}>
                    Show Less
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default ContentDesktop;
