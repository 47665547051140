import React, { useState } from 'react';

import Header from '../components/header';
import Details from './components/details';
import AddTattooModal from './components/addTattooModal';
import ModalQrCode from '../../user/components/qrCodeModal';

const EventsDetails = () => {
  const [addTattooModal, setAddTattooModal] = useState(false);
  const [modalQR, setModalQR] = useState(false);

  return (
    <>
      <Header />
      <Details
          modalOpen={addTattooModal}
          setAddTattooModal={setAddTattooModal}
          setModalQR={setModalQR}
        />
      {addTattooModal && (
        <AddTattooModal
          setAddTattooModal={setAddTattooModal}
        />
      )}
      {modalQR && <ModalQrCode modalQR={modalQR} setModalQR={setModalQR} />}
    </>
  );
};

export default EventsDetails;
