import { useState, useEffect } from 'react';
import Side from './side';
import Header from './header';
import Dashboard from './dashboard';
import { Link, useParams } from 'react-router-dom';
import Create from '../../admin/events/components/create';
import Event from '../../admin/events/components/index';
import Details from '../../admin/events/components/details';
import ModalQrCode from '../../user/components/qrCodeModal';
import Subscription from './subscription';
import Plans from './plans';
import { useStore } from '../../../store/user';

const Content = () => {
  const { route, id } = useParams();
  const [modalQR, setModalQR] = useState(false);
  const user = useStore((state) => state.user);
  const fetchUser = useStore((state) => state.fetchUser);

  useEffect(() => {
    fetchUser(user);
  }, []);
  return (
    <>
      <section class="slice py-5 bg-section-secondary">
        <div class="container min-vh-100">
          <div class="row">
            <div class="col-md-2">
              <Side />
            </div>
            <div class="col-md-10">
              {!route && !id && (
                <>
                  <Header title="Dashboard" />
                  <Dashboard />
                </>
              )}
              {user.organizationId !== null && route === 'events' && (
                <>
                  <Header title="Events" route={route} />
                  <Event isAdminDashboard={false} />
                </>
              )}
              {user.organizationId == null && route === 'events' && (
                <div className="d-flex align-items-center justify-content-center h-100">
                  You need to have a organization in order to create a event
                  <span>
                    .{' '}
                    <Link to="/business-dashboard" className="mb-0">
                      Create one
                    </Link>
                  </span>
                </div>
              )}
              {route === 'create-event' && <Create adminDashBoard={false} />}
              {id && (
                <>
                  <Details
                    adminDashBoard={false}
                    eventIdProps={id}
                    setModalQR={setModalQR}
                  />
                </>
              )}
              {modalQR && (
                <ModalQrCode modalQR={modalQR} setModalQR={setModalQR} />
              )}
              {route === 'subscription' && (
                <>
                  <Header title="Subscription" />
                  <Subscription />
                </>
              )}
              {/* {
                route === 'plans' &&
                <>
                <Header title="Plans"/>
                <Plans />
                </>
              } */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
