import React, { useState } from 'react';
import api from '../../../../utils/api';
import { BiChevronLeft } from 'react-icons/bi';
import { useHistory } from 'react-router-dom'; // Import useHistory


const ChangePasswordForm = ({ onGoBack }) => {
  const history = useHistory(); // Get the history object

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const submitChangePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    if (newPassword !== repeatPassword) {
      setError('Passwords do not match.');
      return;
    }
    try {
      const { email } = JSON.parse(localStorage.getItem('@SKANZ/user'));
      // Assuming you have the user's email stored in local storage

      // Verify the old password by making a sign-in API call
      await api.post('/auth/signIn', {
        email,
        password: oldPassword,
      });

      // If the old password is correct, update the password
      await api.put('/user/info', { password: newPassword });

      // Password successfully changed
      setSuccess('Password successfully changed.');

      // Redirect to the settings page
      history.push('/settings');
    } catch (error) {
      console.log(error);
      if (error.response?.data?.error) setError(error.response.data.error);
    }
  };

  return (
    <div>
      <button type="button" className="clear-icon" onClick={onGoBack}>
        <h2 className="mb-5 text-center ml-5">
        <BiChevronLeft className="icon-md mb-2" />
        <span className="mt-5">Change Password</span></h2>
      </button>
      {error && <div className="alert alert-danger text-center">{error}</div>}
      {success && <div className="alert alert-success text-center">{success}</div>}
      <form onSubmit={(e) => submitChangePassword(e)} className="mt-3">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Old password</label>
              <input
                required
                className="light-input form-control"
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">New password</label>
              <input
                required
                className="light-input form-control"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Confirm password</label>
              <input
                required
                className="light-input form-control"
                type="password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="mt-3 text-right">
          
          <button type="submit" className="btn btn-large btn-warning ml-2">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
