import React from 'react';
import { Link } from 'react-router-dom';
import { BiChevronLeft } from 'react-icons/bi';
import { BsGear } from 'react-icons/bs';

const TopNav = ({ title, goBack }) => {
  return (
    <div className="row col-12 justify-content-between mt-3">
      <button onClick={goBack} className="text-white clear-icon">
        <BiChevronLeft className="icon-lg" />
      </button>
      <h1 className="mt-1">{title}</h1>
      <Link to="/settings" className="text-white mt-2">
        <BsGear className="icon-size gear-icon" />
      </Link>
    </div>
  );
};

export default TopNav;
