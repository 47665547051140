import React, { useState, useEffect } from 'react';

import Header from './components/header';
import DesktopContacts from './components/contacts/desktopContacts';
import MobileContacts from './components/contacts/mobileContacts';
import BottomNav from './components/mobile/bottomNavBar';


const UserContacts = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!isMobile && <Header />}

      {isMobile ? <MobileContacts /> : <DesktopContacts />}

      {isMobile && <BottomNav />}
    </>
  );
};

export default UserContacts;