import React, { useState, useEffect } from 'react';

import Header from './components/header';
import DesktopEvents from './components/events/desktopEvents';
import MobileEvents from './components/events/mobileEvents';
import BottomNav from './components/mobile/bottomNavBar';


const UserEvents = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!isMobile && <Header />}

      {isMobile ? <MobileEvents /> : <DesktopEvents />}

      {isMobile && <BottomNav />}
    </>
  );
};

export default UserEvents;
