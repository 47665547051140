import React, { useState, useEffect } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import api from '../../../../utils/api';

const ChangeNameForm = ({ onGoBack }) => {
  const storedUser = JSON.parse(localStorage.getItem('@SKANZ/user'));
  const [firstName, setFirstName] = useState(storedUser?.firstName || ''); // Use stored user's firstName
  const [lastName, setLastName] = useState(storedUser?.lastName || '');   // Use stored user's lastName
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    // Validate the input (e.g., make sure both first name and last name are provided)
    if (!firstName || !lastName) {
      setError('Both first name and last name are required.');
      return;
    }
  
    try {
      // Make an API request to update the user's name
      const response = await api.put('/user/info', {
        firstName,
        lastName,
      });
  
      if (response.status === 200) {
        // Update the user's name in the component state
        setSuccess('Name successfully changed.');
  
        // Update the user's name in localStorage
        const storedUser = JSON.parse(localStorage.getItem('@SKANZ/user'));
        if (storedUser) {
          storedUser.firstName = firstName;
          storedUser.lastName = lastName;
          localStorage.setItem('@SKANZ/user', JSON.stringify(storedUser));
        }
      } else {
        setError('Failed to update name. Please try again.');
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred while updating the name.');
    }
  };
  return (
    <div>
      <button type="button" className="clear-icon" onClick={onGoBack}>
        <h2 className="mb-5 text-center ml-5">
          <BiChevronLeft className="icon-md mb-2" />
          <span className="mt-5">{`${storedUser?.firstName || ''} ${storedUser?.lastName || ''}`}</span> {/* Display the user's name */}
        </h2>
      </button>
      {error && <div className="alert alert-danger text-center">{error}</div>}
      {success && <div className="alert alert-success text-center">{success}</div>}
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">First Name</label>
              <input
                required
                className="light-input form-control"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Last Name</label>
              <input
                required
                className="light-input form-control"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="mt-3 text-right">
          <button type="submit" className="btn btn-large">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangeNameForm;
