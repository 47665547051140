import React from "react";

import ContentDesktop from "./components/contentDesktop";
import ContentMobile from "./components/contentMobile";

const ExternalProfile = () => {
  const isMobile = window.innerWidth <= 768; // You can set this value based on your design breakpoints

  return (
    <>
      {isMobile ? <ContentMobile /> : <ContentDesktop />}
    </>
  );
};

export default ExternalProfile;