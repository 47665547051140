import React, { useState, useEffect } from 'react';

import Header from "./components/header";
import DesktopSettings from "./components/settings/settingsDesktop";
import MobileSettings from "./components/settings/settingsMobile";
import BottomNav from './components/mobile/bottomNavBar';


const UserSettings = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    {!isMobile && <Header />}

    {isMobile ? <MobileSettings /> : <DesktopSettings />}

    {isMobile && <BottomNav />}
  </>
  );
};

export default UserSettings;
