import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../../../../styles/sass/pages/profile.scss';
import TopNav from '../mobile/topNav';
import { BiChevronLeft } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";

import { Link } from 'react-router-dom';

import BottomNavBar from '../mobile/bottomNavBar';

const EditUserProfile = ({ onSaveChanges }) => {
  // Initialize state values with user data from localStorage
  const storedUserData = JSON.parse(localStorage.getItem('@SKANZ/user')) || {};
  const [bio, setBio] = useState(storedUserData.bio);
  const [email, setEmail] = useState(storedUserData.email);
  const [phone, setPhone] = useState(storedUserData.phone);
  const [customWebsite, setCustomWebsite] = useState(storedUserData.customWebsite);
  const [links, setLinks] = useState(storedUserData.links || []);
  const [showModal, setShowModal] = useState(false);

  // Function to edit a link
  const editLink = (index, name, url) => {
    const updatedLinks = [...links];
    updatedLinks[index] = { name, url };
    setLinks(updatedLinks);
  };

  // Function to add a new link
  const addLink = () => {
    const newLink = { name: '', url: '' };
    setLinks([...links, newLink]);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Function to handle saving changes
  const handleSaveChanges = () => {
    // Implement logic to save changes and pass the updated data to the parent component
    const updatedUserData = {
      ...storedUserData,
      bio,
      email,
      phone,
      customWebsite,
      links,
    };
    onSaveChanges(updatedUserData);
  };



  return (
    <div className="edit-profile">

    <div className="row col-12 justify-content-between mt-3">
      <button className="text-white clear-icon">
        <BiChevronLeft className="icon-lg" />
      </button>
      <h1 className="mt-1">Profile</h1>
      <Link to="/settings" className="text-white mt-2">

      </Link>
    </div>

      <div className="col-11 ml-2">
      <div>
        <h5 htmlFor="bio">Subtitle</h5>
        <input
          id="bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          placeholder="Your subtitle here..."
        />
      </div>

      <button 
      className="btn btn-large mt-5"
      >
        Save Changes
      </button>
      
      <div>
        <button 
        className="btn transparent-btn-large mt-3 mb-3"
        >
          Cancels
        </button>
      </div>
      <BottomNavBar />

    </div>
  </div>
  );
};

export default EditUserProfile;
